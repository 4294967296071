import React, { Fragment } from "react"
import Layout from "../components/Layout"
import LayoutSimple from "../components/layoutSimple"
import NewsletterSubscriptionWrapper from "../components/NewsletterSubscription/NewsletterSubscriptionWrapper"
import SEO from "../components/seo"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
const NewsletterSubscriptionHOC = ({ rId }) => {
  React.useEffect(() => {
    document.title = "Spread the word | voyayge"
  }, [])

  const location = useLocation()

  const getRId = () => {
    try {
      const searchParams = parse(location.search)
      console.log({ searchParams })
      if (searchParams.rId) {
        return searchParams.rId
      } else return ""
    } catch (error) {}
  }

  return (
    <Fragment>
      {/* <SignUpForm type={"traveler"} /> */}
      <NewsletterSubscriptionWrapper rId={getRId()} />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO
      pageTitle="Newsletter Subscription"
      description="Voyayge newsletter delivers curated travel info on the latest trends, unique destinations & free travel itineraries."
    />
    <NewsletterSubscriptionHOC />
  </Layout>
)
